@import "./variables.scss";

/* width */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.flexy {
	display: flex;
	justify-content: space-between;
	& > * {
		flex: 1;
	}
	&.wrapping {
		flex-wrap: wrap;
	}
}

.space-between {
    display: flex;
	justify-content: space-between;    
}

.rounded {
    border-radius: 20px;
    padding: 10px 15px;    
}

.shadow {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
}

.text-center {
    text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
    text-align: right;
}

.invert {
    filter: invert(1);
}

.pointer {
    cursor: pointer;
}

.align-center {
    display: inline-block;
    vertical-align: middle;
}

.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.transition {
    transition: all 0.1s ease;
}

.field-combiner {
    & > * {
        margin-bottom: 17px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
    @media (min-width: $break_md) {
        display: flex;
        & > * {
            margin-bottom: 0px;
            flex: 10;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.availableColor-bg {
	background-color: $availableColor;
}
.availableColor-c {
	color: $availableColor;
}
.availableColor-fill {
	fill: $availableColor;
}
.availableColorEntertainment-bg {
	background-color: $availableColorEntertainment;
}
.availableColorEntertainment-c {
	color: $availableColorEntertainment;
}
.availableColorEntertainment-fill {
	fill: $availableColorEntertainment;
}
.bayColor-bg {
	background-color: $bayColor;
}
.bayColor-c {
	color: $bayColor;
}
.bayColor-fill {
	fill: $bayColor;
}
.bookedColor-bg {
	background-color: $bookedColor;
}
.bookedColor-c {
	color: $bookedColor;
}
.bookedColor-fill {
	fill: $bookedColor;
}
.selectedColor-fill {
    fill: $selectedColor;
}
.selectedColor-bg {
    background-color: $selectedColor;
}
.selectedColor-c {
    color: $selectedColor;
}
.nullColor-bg {
	background-color: $nullColor;
}
.nullColor-c {
	color: $nullColor;
}
.nullColor-fill {
	fill: $nullColor;
}

.maximum-width {
    display: block;
    width: 90%;
	max-width: 800px;
	margin: auto;
}

.padded-1 {
    padding: 15px 0px;
}

.padded-h-1 {
    padding: 0px 10px;
}

.v-middle {
    & > * {
        display: inline-block;
        vertical-align: middle;
    }
}

.chameleon {
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    padding: 0px;
    border: 1px solid transparent;
    margin: 0px;
    text-transform: inherit;
    display: inline-block;
}

.hide-on-mobile {
    display: none;
    @media (min-width: $break_xs) {
        display: block;
    }
}

.warning-stripy {
    background-color: #ffdde7;
    background-image: repeating-linear-gradient(60deg, transparent, transparent 6px, #ffb7cc 6px, #ffb7cc 12px);
}