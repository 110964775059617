$bg1: #ffffff;
$bg2: #e6e6e6;
$bg3: #000000;
$bg4: #cccccc;
$bg5: #00ba89;
$bg6: #262626;
$bg7: #4d4d4d;
$bg8: #1c1c1c;
$dark1: #333333;

$availableColor: #00d355;
$availableColorEntertainment: #0094a5;
$bookedColor: #e20046;
$attendedColor: #d996fc;
$selectedColor: #000000;
$nullColor: #9B9B9B;
$bayColor: #00ba89;
$paleRed: #f2dde6;
$darkRed: #9e0040;

$break_xs: 576px;
$break_sm: 768px;
$break_md: 992px;
$break_lg: 1200px;
$break_xl: 1500px;
$break_xxl: 1800px;