@import "../style/assets.scss";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');

* {
	box-sizing: border-box;
	position: relative;
}

body, html {
	margin: 0;
	height: 100%;
}

h1, h2, h3, h4, h5, .featureFont {
	font-family: "Montserrat", sans-serif;
	text-transform: uppercase;
	font-weight: 300;
	margin: 0px;
	display: block;
	b {
		font-weight: 700;
	}
}

#root {
	height: 100%;
}

$headerHeight: 70px;
$headerHeightDesktop: 80px;

.group-summary {
	.heading {
		font-size: 1.1rem;
		.session {
			background-color: #333;
			color: $bg1;
			margin-right: 10px;
			white-space: pre;
		}	
	}
    .booking {
        border-bottom: 1px solid #d5d5d5;
        .countdown {
            padding-top: 5px;
            font-size: 1rem;
            color: $bookedColor;
            font-weight: bold;
        }
        &:last-of-type {
            border-bottom: none;
        }
    }
	.booking,
    .clubs,
    .session-summary {
		font-size: 1.2rem;
		padding: 20px 0px;
		@media (min-width: $break_xs) {
            font-size: 1.5rem;
            .countdown {
                font-size: 1.2rem;
                padding-top: 2px;
            }
		}
	}
}	

#booking-view {
	height: 100%;
	font-family: sans-serif;
	.login-widget {
		background-color: $bg1;
		padding: 13px 30px;
		padding-left: 80px;
		border-radius: 50px;
		display: inline-block;
		.icon {
			position: absolute;
			left: 12px;
			top: 50%;
			transform: translateY(-50%);
			border: 1px solid $bg2;
			border-radius: 50%;
		}
		div {
			.featureFont {
				font-weight: bold;
				overflow-x: hidden;
				white-space: pre;
				max-width: 150px;
				text-overflow: ellipsis;
			}
			button {
				border-radius: 2px;
				margin-top: 7px;
				padding: 5px 10px;
				background-color: $bayColor;
			}
		} 
	}
	.summary {
		.group-summary {
			padding: 40px 0px;
			border-bottom: 9px solid #e6e6e6;
			&:first-of-type {
				padding-top: 0px;
			}
			.heading {
				padding-top: 20px;
			}
            .booking,
            .clubs {
				border-bottom: 1px solid #f1f1f1;
			}	
		}
	}	
	.upDownArrows {
		display: inline-block;
		font-size: 0;
		border-radius: 30px;
		box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
		background-color: $bg1;
		overflow: hidden;
		.arrow {
			display: block;
			&.disabled {
				opacity: 0.3;
			}
			&:not(.disabled) {
				cursor: pointer;
				&:hover {
					background-color: #f7f7f7;
				}
			}
			&.up {
				padding: 15px 15px 10px 15px;
			}
			&.down {
				padding: 10px 15px 15px 15px;
				.imgIcon {
					transform: rotate(180deg);
				}
			}
		}
	}
	
	.date-selector {
		text-transform: uppercase;
		background-color: $bg3;
		color: $bg1;
		padding: 15px 20px 22px 20px;
		border-radius: 100px;
		margin: 0 auto;
		display: inline-block;
		width: 75%;
		max-width: 250px;
		transform: translate(-50%, -175%);
		position: absolute;
        left: 50%;
        .date-wrapper {
            margin-bottom: 10px;
            .currentTime {
                font-size: 1.5rem;
            }
            .currentDate {
                font-size: 1.5rem;
            }
        }
		.controls {
			display: flex;
			justify-content: center;
			position: absolute;
			bottom: -50px;
			left: 50%;
			transform: translate(-50%, -50%);
			.arrows {
				display: flex;
				font-size: 0;
				border-radius: 30px;
				box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
				background-color: $bg1;
				overflow: hidden;
				margin-right: 10px;
				.arrow {
					padding: 15px;
					&.disabled {
						opacity: 0.3;
					}
					&:not(.disabled) {
						cursor: pointer;
						&:hover {
							background-color: #f7f7f7;
						}
					}
					&.left {
						transform: rotate(-90deg);
					}
					&.right {
						transform: rotate(90deg);
					}
				}	
			}
			.paddedIcon {
				padding: 15px !important;
				&:hover {
					background-color: #f7f7f7 !important;
				}	
			}	
		}
		.desktop {
			display: none;
		}
		@media (min-width: $break_xs) {
            transform: translate(-50%, -130%);
            width: 90%;
            max-width: 270px;
            padding: 15px 20px 25px 20px;
            .date-wrapper {
                margin-bottom: 0px;
                .currentDate {
                    font-size: 1.3rem;
                    margin-bottom: 5px;
				}
				.currentTime {
					font-size: 3rem;
					margin-bottom: 8px;
				}
            } 
			.desktop {
				display: block;
			}
			.mobile {
				display: none;
			}
        }
        @media (min-width: $break_sm) {
            transform: translate(-50%, -160%);    
        }
	}
	
	.time-selector {
		@extend .noSelect;
		.time-slots {
			padding: 40px 10px;
			height: 30vh;
			min-height: 250px;
			overflow: auto;
			margin: -20px 0px;
		}
		.slotWrapper {
			padding: 5px 0px;
		}
		.time-slot {
			background-color: $bg2;
			padding: 30px 10px;
			align-items: center;
			transition: background-color 0.2s ease, color 0.2s ease, opacity 0.2s ease;
			&:not(.expanded) {
				cursor: pointer;
			}
			.time {
				font-size: 2rem;
				transition: height 0.2s ease, opacity 0.2s ease;
				font-weight: bold;
				.from, .to {
					display: block;
				}
				.to {
					margin-left: 5px;
					opacity: 0.5;
					font-size: 1.5rem;
				}
			}
			&:last-child {
				margin-bottom: 0px;
			}
			&.big {
				font-size: 2.5rem;
				cursor: pointer;
			}
			&.out-of-hours {
				opacity: 0.2;	
			}
			.bayStatus {
				position: absolute;
				top: 5px;
				right: 5px;
				padding: 5px 8px;
				display: inline-block;
				font-weight: bold;
				&.bayStatus-peak {
					color: #ffffff;
					background-color: #000000;
				}
				&.bayStatus-offPeak {
					color: #ffffff;
					background-color: $bayColor;
				}
			}
			.bay-types {
				padding-left: 20px;
				.flexy {
					align-items: center;
				}
				label,
				.b-type {
					font-size: 1.5rem;
					margin-left: 15px;
				}
				.checkbox-field {
					padding: 10px 0px 10px 36px;
					label {
						margin: 0;
					}
				}
				.quantity-selector {
					vertical-align: middle;
					display: inline-block;
				}
			}

			// Expansion logic.
			opacity: 0.5;
			.bay-types {
				opacity: 0;
				// height: 0px;
				transition: height 0.2s ease, opacity 0.2s ease;
				overflow-y: hidden;
			}
			&.booked {
				background-color: $bayColor;
				color: white;
			}
			&.expanded {
				opacity: 1;
			}
			&.expanded, &.booked, &:hover {
				.bay-types {
					opacity: 1;
					// height: 80px;
				}
			}
		}	
	}

	.time-selector-horizontal {
		cursor: default;
		.time-slots {
			overflow-x: scroll;
			scroll-behavior: smooth;
			.time-slots-inner {
				display: inline;
				white-space: nowrap;
				.slotWrapper {
					padding: 0px 2px;
					.time-slot {
						color: white;
						background-color: black;
						width: 220px;
						transition: all 0.2s ease;
						.time {
							padding: 17px 17px 0px 17px;
							font-size: 2rem;
							font-weight: bold;
						}
						.options {
							padding: 17px;
							.option {
								cursor: pointer;
								padding: 10px 10px 10px 40px;
								border: 2px dashed white;
								margin-bottom: 15px;
								font-size: 1.2rem;
								opacity: 0.7;
								transition: all 0.2s ease;
								.imgIcon {
									position: absolute;
									left: 5px;
									top: 10px;
									filter: invert(1);
								}
								&:last-of-type {
									margin-bottom: 0px;
								}
								&.disabled {
									// background-color: red;
									opacity: 0.5;
									cursor: default;
									&:before {
										content: "";
										display: block;
										position: absolute;
										pointer-events: none;
										height: 4px;
										border-radius: 5px;
										width: 105%;
										background-color: red;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%) rotate(17deg);
									}
								}
								&.selected {
									opacity: 1;
									// border-color: $bayColor;
									&:after {
										$checksize: 30px;
										content: "\2713";
										font-size: $checksize/1.5;
										right: -6px;
										bottom: -6px;
										font-weight: bold;
										display: block;
										position: absolute;
										pointer-events: none;
										text-align: center;
										line-height: $checksize;
										height: $checksize;
										width: $checksize;
										border-radius: $checksize/2;
										background-color: $bayColor;
										// background-color: black;
										color: white;
									}
								}
								&:not(.disabled):not(.selected) {
									&:hover {
										opacity: 1;
									}
								}
								.spinner-overlay {
									background-color: rgba(0,0,0, 0.9);
									position: absolute;
									top: 0;
									left: 0;
									right: 0;
									bottom: 0;
									& > div {
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
									}
								}
							}
						}
						&:not(.booked).inactive {
							transform: scale(0.95);
							opacity: 0.7;
						}
					}
				}
            }
		}
		& > .side-gradient {
			pointer-events: none;
			position: absolute;
			top: 0px;
			height: 100%;
			width: 10px;
            background-color: red;
            display: none;
			&.side-gradient-left {
				left: 30px;
				background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 100%);
			}
			&.side-gradient-right {
				right: 30px;
				background: linear-gradient(-90deg, white 0%, rgba(255, 255, 255, 0) 100%);
			}
		}
		& > .arrow {
			font-size: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			padding: 15px;
			border-radius: 50px;
			cursor: pointer;
			background-color: white;
			opacity: 0.9;
			transition: all 0.1s ease;
			&:hover {
				opacity: 1;
				transform: translateY(-50%) scale(1.1);
			}
			&.arrow-left {
				left: 0px;
				.imgIcon {
					transform: rotate(-90deg);
				}
			}
			&.arrow-right {
				right: 0px;
				.imgIcon {
					transform: rotate(90deg);
				}
			}
        }
        @media (min-width: $break_xs) {
            padding: 0px 30px;
            // .time-slots {
            //     overflow-x: hidden;
            // }
            .side-gradient {
                display: block;
            }
        }
	}

	.questionSet {
		@extend .noSelect;

		.question {
			transition: all 0.3s ease;
			padding: 10px;
			max-width: 600px;
			margin: auto;
			h4 {
				font-size: 1.2rem;
			}
			.details {
				@extend .featureFont;
				text-transform: none;
			}
			& > .inner > .controls {
				button {
					border-radius: 3px;
					margin: 0px 2px;
					&:first-child {
						border-top-left-radius: 20px;
						border-bottom-left-radius: 20px;
					}
					&:last-child {
						border-top-right-radius: 20px;
						border-bottom-right-radius: 20px;
					}
				}
			}
			a {
				color: cornflowerblue;
				text-decoration: none;
				&:hover {
					text-decoration: underline;
				}
			}
			& > .inner {
				padding: 10px;
			}
			.error {
				color: #e20046;
				background-color: #fef2f6;
				text-transform: none;
            }
            .clubs-question,
			.players-question {
				& > .flexy {
					justify-content: center;
                }
                .iron, .wood,
				.adults, .children {
					padding: 10px 10px 10px 10px;
					min-width: 280px;
					max-width: 320px;
					.text-left {
						width: 90px;
					}
					span.featureFont {
						font-size: 1.3rem;
						margin-bottom: 3px;
					}
					.upDownArrows {
						margin-left: 20px;
					}
					.icon {
						height: 50px;
						width: 50px;
						margin-right: 30px;
						.imgIcon {
							opacity: 0.8;
						}
					}
					.count {
						margin: 0px;
						position: absolute;
						background-color: black;
						color: white;
						height: 35px;
						line-height: 35px;
						width: 35px;
						border-radius: 50px;
						font-weight: bold;
						bottom: -10px;
						right: -10px;
						font-size: 1.3rem;
					}
					&.children {
						.imgIcon {
							transform: scale(0.8);
						}
					}
				}
            }
            .bays-question {
                h5 {
                    text-transform: none;
                    font-size: 1.11rem;
                    @media (min-width: $break_xs) {
                        font-size: 1.17rem;
                    }
                }
                .bay-info {
                    padding: 10px;
                    font-size: 1.1rem;
                    background-color: #f0f0f0;
                }
                .bays-row {
                    padding: 10px 0px 20px 0px;
                    .bay {
						color: white;
						text-align: center;
						width: 25px;
						height: 33px;
						line-height: 33px;
						font-weight: bold;
						margin: 0px 2px 4px 2px;
						cursor: pointer;
						opacity: 0.8;
						transition: opacity 0.2s ease;
						border-radius: 2px;
                        &.standard {
                            background-color: $bayColor;
                        }
                        &.entertainment {
                            background-color: $availableColorEntertainment;
                        }
                        &.selected {
                            background-color: $bg3;
                        }
                        &:hover {
                            opacity: 1;
                        }
                    }
                }    
            }
			&.questionStage-enter {
				opacity: 0;
				transform: translateY(30px);
			}
			&.questionStage-enter-active {
				opacity: 1;
				transform: translateY(0);
			}
			&.questionStage-enter-done {
				opacity: 1;
				transform: translateY(0);
			}
	
			&.questionStage-exit {
				opacity: 1;
				transform: translateY(0px);
			}
			&.questionStage-exit-active {
				opacity: 0;
				transform: translateY(-30px);
			}
			&.questionStage-exit-done {
				opacity: 0;
				transform: translateY(-30px);
			}
		}

	}
	
	$headWidth: 90px;
	$headHeight: 75px;

	$headWidthLarge: 100px;
	$headHeightLarge: 80px;
	
	.booking-group {
		padding: 30px 0px;
		&:not(:last-child) {
			border-bottom: 9px solid $bg2;
		}
		& > div {
			padding: 15px 10px;
			margin: 5px 0px;
			.upDownArrows {
				z-index: 1;
			}
			&.errored {
				.number-selector {
					.head {
						background-color: $bookedColor;
					}
					.body {
						color: $bookedColor;
						background-color: $paleRed; 
					}
				}
				.error {
					color: $bg1;
					background-color: $darkRed;
					margin: 0 auto;
					padding: 20px 5px;
					width: 90%;
					border-radius: 0px 0px 10px 10px;
					font-size: 1em;
					img {
						width: 20px;
						margin-right: 5px;	
					}
					@media (min-width: $break_md) {
						font-size: 1.4rem;
						img {
							width: 30px;
							margin-right: 10px; 
						}
					}
				}
			}
		}
		.summary {
			.left,
			.right {
				display: flex;
				align-items: flex-end;
				img {
					width: 25px;
				}
				div {
					padding-left: 5px;
					font-size: 1.3rem;
				}
			}
			.right {
				justify-content: flex-end;
				img {
					opacity: 0.5;
				}
				div {
					padding: 0 10px 0 0;
				}
			}   
		}
		.bookings {
			.booking {
				display: flex;
				font-size: 1.2rem;
				align-items: center;
				padding-left: $headWidth;
				&:not(:last-child) {
					margin-bottom: 15px;
				}
				.bay {
					background-color: $bayColor;
					width: $headWidth;
					height: $headHeight;
					color: white;
					border-radius: 10px 0px 0px 10px;
					position: absolute;
					top: 0;
					left: 0;
					div {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100%;
						b {
							padding-left: 5px;
							font-size: 2.1rem;
						}
					}
				}
				.entertainment-badge {
					position: absolute;
					left: $headWidth;
					top: $headHeight / 2;
					z-index: 10;
					transform: translate(-50%, -50%);
					border-radius: 50%;
					padding: 3px;
					background-color: rgba(255,255,255,0.2);
					.inner {
						border-radius: 50%;
						padding: 1.5px;
						background-color: rgba(255,255,255,0.4);
					}
				}
				.times {
					min-height: $headHeight;
					text-align: left;
					width: 100%;
                    background-color: $bg2;
                    border-radius: 0px 10px 10px 10px;
					&.entertainment {
						background-color: #f2e8dd;
					}
					.time {
                        padding: 19px 35px 19px 10px;
                        &.extra-padding {
                            padding: 29px 35px 29px 10px;    
                        }
                        .imgIcon {
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        .countdown {
                            padding-top: 5px;
                            font-size: 1rem;
                            color: $bookedColor;
                            font-weight: bold;
                        }
						&:not(:last-child) {
							border-bottom: 1px solid $bg4;
						}
					}
				}
				@media (min-width: $break_xs) {
					padding-left: $headWidthLarge;
					font-size: 1.5rem;  
					.bay {
						width: $headWidthLarge;
						height: $headHeightLarge;
						div {
							b {
								font-size: 2.5rem;
							}
						}	
					}
					.entertainment-badge {
						top: $headHeightLarge / 2;
						left: $headWidthLarge;	
					} 
					.times {
                        min-height: $headHeightLarge;
                        .time {
                            .countdown {
                                font-size: 1.2rem;
                                padding-top: 2px;
                            }
                        }
					}   
				}
			}
		}
	}
	
	.number-selector {
		padding-left: $headWidth;
		// margin: 20px 0px;
		.head {
			background-color: #000000;
			width: $headWidth;
			height: $headHeight;
			color: white;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 10px 0px 0px 10px;
			display: flex;
			justify-content: center;
			.name {
				align-self: center;
				width: 80px;
				img {
					width: 30px;
				}
				div {
					font-size: 1rem;
				}
			}
		}
		.body {
			background-color: $bg2;
			height: $headHeight;
			border-radius: 0px 10px 10px 0px;
			.option {
				padding-left: 15px;
				font-size: 1.2rem;
				align-self: center;
				.upDownArrows {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					.arrow {
						&.up {
							padding: 20px 15px 15px 15px;
						}
						&.down {
							padding: 15px 15px 20px 15px;	
						}
					}
				}   
			}
		}
		@media (min-width: $break_xs) {
			padding-left: $headWidthLarge;
			.head {
				width: $headWidthLarge;
				height: $headHeightLarge;
				.name {
					div {
						font-size: 1.2rem;
					}
				}	
			}
			.body {
				height: $headHeightLarge;
				.option {
					padding-left: 20px;
					font-size: 1.5rem;
				}
			}	
		}   
	}
		
	.after-18 {
		.user-card {
			.inner {
				padding: 10px;
				background-color: $bg2;
				border-radius: 10px;
				.imgIcon {
					opacity: 0.4;
				}
			}
			.details {
				width: 40px;
				.line {
					height: 3px;
					background-color: $dark1;
					opacity: 0.3;
					margin-bottom: 2.5px;
					&.big {
						height: 5px;
						margin-bottom: 4px;   
					}
				}
			}
		}
		.info {
			font-size: 1.4rem;
			margin-left: 15px;
		}
		.spacer {
			margin-bottom: 10px;
			@media (min-width: $break_xs) {
				display: inline-block;
				vertical-align: middle;
				margin: 0px 5px;
			}
		}
	}
		
	.order-tile {
		margin-bottom: 20px;
		overflow: hidden;
		border: 1px solid #cccccc;
		border-radius: 2px;
		background-color: white;
		&.cancelled {
			border: 1px solid $bookedColor;
			opacity: 0.5;   
			.cancellationMessage {
				text-align: center;
				padding: 10px;
				background-color: #e20046;
				color: #ffffff;
				font-size: 1.1rem;
			} 
		}
		.header {
			padding: 10px 10px 10px 60px;
			background-color: $bg2;
			border-bottom: 1px solid $bg4;
			b {
				font-size: 1.2rem;
			}
			.order-id {
				font-size: 2rem;
				position: absolute;
				top: 50%;
				left: 10px;
				transform: translateY(-50%);
			}
		}
		.content {
			padding: 10px;
			.item,
			.person {
				margin-bottom: 6px;
				padding-left: 20px;
				.imgIcon {
					position: absolute;
					left: 0;
					top: 0;
				}
				&.extra {
					.imgIcon {
						filter: invert(1);
					}
				}
			}
			.people {
				margin-top: 15px;
			}
		}
		.refunded {
			padding: 0px 10px 10px 10px;
			div {
				padding: 6px;
				font-size: 1.2rem;
				color: white;
				background-color: $bookedColor;
			}
		}
		.cancel-order {
			color: white;
			font-size: 1.1rem;
			padding: 10px;
			border-radius: 0px;
			background-color: $dark1;
		}
		@media (min-width: $break_sm) {
			.content {
				display: flex;
				.people {
					margin-top: 0px;
					flex: 1;
				}
				.items {
					flex: 3;
				}
			}
		}
	}
		
	.overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		pointer-events: none;
		background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 0) 95%, white 100%);
	}
	
	hr {
		border: none;
		border-top: 2px solid #f7f7f7;
	}
		
	.page {
		// height: 100%;
		overflow-y: auto;
        padding: 0px 0px $headerHeight 0px;
        .full-content-height {
            height: calc(100vh - #{($headerHeight + 2.5) * 2}) !important;
        } 
		header {
			height: $headHeight;
			.login-widget {
				position: absolute;
				right: 0px;
				top: 0px;
				bottom: 0px;
				border-radius: 0px;
				background-color: #f8f8f8;
				border-bottom: 1px solid $bg2;
				.imgIcon {
					opacity: 0.8;
				}
			}
		}
		footer {
			position: fixed;
			width: 100%;
			left: 0px;
			height: $headerHeight;
			z-index: 99;
		}
		@media (min-width: $break_md) {
            padding: 0px 0px $headerHeightDesktop 0px;
            .full-content-height {
                height: calc(100vh - #{$headerHeightDesktop * 2}) !important;
            } 
			header, footer {
				height: $headerHeightDesktop;
				.login-widget {
					border-radius: 40px;
					background-color: $bg1;
					box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
					top: 100%;
					right: initial;
					left: 75vw;
					transform: translateY(-50%);
					bottom: initial;
					border-bottom: none;
				}
			}
		}
		header {
			top: 0px;
			background-image: url("/media/header1.jpg");
			background-size: cover;
			background-position: center;
			box-shadow: inset 0px -5px 10px rgba(0, 0, 0, 0.2);
			z-index: 1;
		}
		footer {
			bottom: 0px;
			background-color: #1e1e1e;
			color: $bg1;
			line-height: $headerHeight;
			font-size: 2.7vh;
			& > .inner {
				height: 100%;
				transition: background-color 0.3s ease, color 0.3s ease;
				&.errored {
					background-color: $bookedColor;
					.errors {
						top: 50%;
						transform: translateY(-50%);
						width: 100%;
						font-size: 1.2rem;
						line-height: initial;
						padding: 0px 10px;
						@media (min-width: $break_md) {
							font-size: 1.5rem;
						}
					}
				}
			}
			.round-btn {
				background-color: $bg3;
				line-height: initial;
				border-radius: 50%;
				color: $bg1;
				padding: 6px;
				width: 50px;
				height: 50px;
				line-height: initial;
				font-size: initial;
				margin-left: 10px;
				img {
					width: 20px;
				}
				div {
					line-height: 0.9rem;
					font-size: 0.9rem;
				}
				@media (min-width: $break_md) {
					width: 60px;
					height: 60px;
					img {
						width: 25px;
					}
					div {
						line-height: 1.1rem;
						font-size: 1.1rem;	
					}
				}
			}
			.next-stage-btn,
			.prev-stage-btn {
				// background-color: $bg3;
				background-color: rgba(0,0,0, 0.2);
				display: block;
				text-decoration: none;
				position: absolute;
				width: 15vh;
				top: 0;
				bottom: 0;
				font-weight: bold;
				color: $bg1;
			}
			.prev-stage-btn {
				left: 0;
			}
			.next-stage-btn {
				right: 0;
			}
			@media (min-width: $break_md) {
				line-height: $headerHeightDesktop;
			}
		}
		.pageContent {
			// overflow-y: auto;
			height: 100%;
			.dots {
				background-image: url("/media/dotted-bg.png");
				background-repeat: no-repeat;
				background-position: top left;
				background-attachment: fixed;
				opacity: 0.2;
				@media (min-width: $break_md) {
					opacity: 1;
				}
			}
			h2 {
				text-align: center;
				font-size: 2.5rem;
				padding: 30px 0px;
			}
			h3 {
				text-align: center;
				padding: 0px 20px 30px 20px;
				margin-top: -20px;
				line-height: 25px;
				text-transform: none;
			}
			.error {
				color: red;
				padding: 15px;
				font-size: 1.1rem;
			}
		}
		// Bays page
		.bays {
			// padding: 20px;
			padding-bottom: 10px;
			width: 100%;
            margin: 0px auto;
            & > h2 {
                font-size: 2.1rem;
                padding: 20px;
            }
            & > h3 {
                font-size: 1.1rem;
            }
			.baySelection {
                text-align: center;
                margin-bottom: 0px;
				.bay-graphic {
					min-height: 180px;
					.bay-color {
						transition: fill 0.15s ease;
					}
                }
                @media (min-width: $break_xs) {
                    margin-bottom: 0px;
                    .bay-graphic {
                        min-height: 200px;
                    }
				}
				@media (min-width: $break_sm) {
					margin-bottom: 0px;
				}
			}
			.time-selector {
				.upDownArrows {
					position: absolute;
					top: 30%;
					right: 0;
				}    
            }
            @media (min-width: $break_xs) {
                padding-bottom: 50px;
                h2 {
                    font-size: 2.5rem;
                    padding: 30px;
                }
                h3 {
                    font-size: 1.17rem;
                }
            }    
		}
		// Sessions page
		.sessions {
			padding-bottom: 50px;
			width: 100%;
            margin: 0px auto;
            .attention {
                margin-top: 15px;
            }  
			& > h3 {
				padding-bottom: 0px;
			}  
		}
		// Checkout page
		.checkout {
			padding: 0px 20px 50px 20px;
			width: 100%;
			margin: 0px auto;  
			.user-account {
				padding-bottom: 27px;
				border-bottom: 1px solid $bg2;
				.user-btn {
					font-size: 1.1rem;
					width: initial;
					color: #000;
					background-color: $bg2;
					border: 1px solid $bg4;
					padding: 10px 20px;
					&.selected {
						color: white;
						background-color: $bg4;
					}
				}	   
			}
			.total {
				padding: 27px 0px;
				font-size: 1.8rem;
			}
			.stripe-btn {
				font-size: 1.5rem;
				border-radius: 30px;
			}	 
		}
		// My orders page
		.my-orders {
			padding: 20px 0px;
			.pagination {
				position: fixed;
				bottom: 5vh;
				left: 50%;
				transform: translate(-50%, 50%);
			}
		}
	}

	.as-link {
		color: cornflowerblue;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
}

.booking-legend {
	.bay-types {
		flex-wrap: wrap;
		& > div {
			white-space: pre;
			padding: 10px;
			min-width: 135px;
			& > span {
				margin-right: 10px;
			}
		}
	}
}
.dummyBay {
	color: #ffffff;
	display: inline-block;
	line-height: 25px;
	width: 20px;
	text-align: center;
	font-family: "Montserrat", sans-serif;
	font-weight: bold;
	font-size: 11px;
	border-radius: 2px;
}