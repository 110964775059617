@import "./variables.scss";
@import "./misc.scss";

.dropdown {
    .current-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $dark1;
        background-color: white;
        .chevron {
            width: 15px;
        }
        &.open {
            .chevron {
                transform: rotate(180deg);
            }
        }
    }   
}

.durationSlider {
	padding: 20px 0px 40px 0px;
	.slider-inner {
		height: 15px;
		.slider-sections {
			height: 100%;
			cursor: pointer;
			.slider-section {
				height: 100%;
				background-color: #f0f0f0;
				&:first-of-type {
					// flex: 0.5;
					border-radius: 10px 0px 0px 10px;
				}
				&:last-of-type {
					// flex: 0.5;
					border-radius:  0px 10px 10px 0px;
				}
				&.slider-section-disabled {
					@extend .warning-stripy;
					cursor: default;
					.section-label {
						color: #e20046;
						font-weight: bold;
						&:after {
							display: none;
						}
					}
				}
				.section-label {
					position: absolute;
					top: 30px;
					right: 0px;
					// transform: translateX(50%);
					&:after {
						content: "";
						position: absolute;
						top: -24px;
						right: -1px;
						font-weight: normal;
						height: 20px;
						border-right-width: 1px;
						border-right-style: solid;
						border-color: #d1d1d1;
					}
				}
				&.slider-section-selected {
					.section-label {
						font-weight: bold;
					}
				}
			}
			.section-node {
				opacity: 0;
				z-index: 1;
				height: 40px;
				width: 40px;
				border-radius: 20px;
				background-color: inherit;
				position: absolute;
				top: 50%;
				right: 0px;
				transform: translate(50%, -50%);
			}
		}
		.slider-node {
			transition: left 0.2s ease;
			z-index: 1;
			height: 25px;
			width: 25px;
			border-radius: 25px;
			background-color: white;
			box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		.slider-fill {
			z-index: 1;
			transition: width 0.2s ease;
			background-color: $bayColor;
			position: absolute;
			height: 100%;
			left: 0;
			top: 0;
			border-radius: 10px;
			pointer-events: none;
		}
	}
}

.user-lookup {
    .lookup {
        .input-field {
            input {
                font-size: 1.5rem;
                padding-right: 42px;
            }
        }
        .imgIcon {
            position: absolute;
            right: 15px;
            top: 10px;
        }
    }    
}

.user-lookup,
.dropdown {
    .options {
        border-radius: 20px;
        position: absolute;
        top: 46px;
        left: 0px;
        width: 100%;
        z-index: 20;
        overflow: hidden;
        .inner {
            overflow-y: auto;
            .option {
                background-color: $bg1;
                color: $dark1;
                padding: 10px 15px;
                &.no-matches {
                    cursor: initial;
                }
                &:not(.no-matches):hover {
                    background-color: $bg2;
                }
            }
        }
	}
	&.user-lookup {
		.options {
			font-size: 1.3rem;
			span, i {
				display: block;
				width: 100%;
				overflow-x: hidden;
				text-overflow: ellipsis;
			}
			span {
				margin-bottom: 2px;
			}
		}
	}
}

input {
    background-color: #ffffff;
    border-radius: 20px;
    padding: 10px 15px;
    width: 100%;
    outline: none;
    border: none;
}

.input-field {
    label   {
        font-weight: bold;
        margin-bottom: 10px;
        display: block;
        span {
            color: $bg5;
            margin-left: 2px;
            font-weight: bold;
        }
    }
}

.checkbox-field {
    padding-left: 33px;
    label {
        margin-bottom: 0px;
        cursor: pointer;
    }
    .box {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        border-radius: 50%;
        background-color: $bg1;
        .inner {
            padding: 3px;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            transform: scale(0);
            img {
                width: 100%;
                filter: invert(1);
            }
        }
        &.selected {
            .inner {
                transform: scale(1);
            }
        }
    }
    &.disabled {
        label {
            cursor: initial;
            opacity: 0.3;
        }
        .box {
            cursor: initial;
            .inner {
                transform: scale(1);
            }   
        }
    } 
}

button {
    text-align: center;
    color: white;
    background-color: black;
    width: 100%;
	display: block;
	border: none;    font-weight: bold;
	transition: all 0.1s ease;
	cursor: default;
    opacity: 0.8;
    border-radius: 20px;
    padding: 10px 15px;
    outline: none;
	&:disabled, &.disabled {
		opacity: 0.2;
	}
	&:hover:not(:disabled), &.loading {
		opacity: 1;
	}
	&:not(.loading):not(:disabled) {
		cursor: pointer;
	}
}

.date-field {
    label {
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
    }
    .inner-field {
        background-color: $bg1;
        padding: 8.5px 10px;
        border-radius: 20px;
        cursor: pointer;
        img {
            width: 15px;
            height: 15px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .calendar-wrapper {
        z-index: 100;
        position: absolute;
        transform-origin: top;
        transform: scale(0);
        transition: transform 0.2s ease;
        &.visible {
            transform: scale(1);
        }
    }
}