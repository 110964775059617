@import "./variables.scss";
@import "./misc.scss";

.user-login {
    height: 100%;
    background-color: $bg2;
    padding-top: 12vh;
    .login-box {
        text-align: center;
        width: 90%;
        max-width: 450px;
        margin: 0 auto;
        padding: 30px 20px;
        border-radius: 20px;
    }    
}

.login {
    .attention {
        margin-bottom: 15px;
    }
    form {
        .field-wrapper {
            margin-bottom: 15px;
            label {
				text-align: left;
				font-weight: normal;
				margin-bottom: 0px;
			}
			input {
				font-size: 1rem;
				font-weight: bold;
				padding: 10px;
				background-color: white;
				border-radius: 0;
				border: none;
				border-bottom: 2px solid $bg4;
				&:focus {
					border-bottom: 2px solid $bg5;
				}
            }
            .tooltip {
                padding: 5px;
                color: $bookedColor;
            }
        }
        .error {
            color: $bookedColor;    
        }
    }
    .link {
        cursor: pointer;
        span {
            color: #00ba89;
            font-weight: bold;
        }
    }    
}

.calendar {
    .DayPicker {
        background-color: white;
        border-radius: 5px;
        .DayPicker-NavBar {
            z-index: 3;
        }
        .DayPicker-Body {
            .DayPicker-Week {
                .DayPicker-Day {
                    &.DayPicker-Day--selected {
                        background-color: $bayColor;
                        color: white;
                    }
                }
            }
        }
    }
}

.lds-dual-ring {
    display: block;
    margin: auto;
	width: 80px;
	height: 80px;
	font-size: 0;
	overflow: hidden;
	.inner {
		content: "";
		position: absolute;
		display: block;
		width: 80%;
		height: 80%;
		margin: 10%;
		border-radius: 50%;
		border: 6px solid #fff;
		border-color: #fff transparent #fff transparent;
		animation: lds-dual-ring 1.2s linear infinite;
	}
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);	
	}
	100% {
		transform: rotate(360deg);
	}
}

.modal-wrapper {
	position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0px;
    overflow: auto;
	@media (min-width: $break_sm) {
		padding: 0px 2vw;
    }
    .close-on-click {
        height: 100%;
        @media (min-width: $break_sm) {
            padding: 50px 13vw;
        }     
    }
	.modal-inner {
		// min-height: 400px;
		margin: auto;
		height: 100vh;
        width: 100%;
        max-width: 1000px;
		background-color: $bg2;
        padding: 40px 20px 20px 20px;
        .close-modal {
            @extend .noSelect;
            position: absolute;
            top: 10px;
            right: 10px;
            text-align: center;
        }
		@media (min-width: $break_sm) {
            border-radius: 20px;
			height: initial;
		}
	}

	// Modal animations.
	$modal-anim-speed: 0.3s;
	&.modal-enter {
		transition: all $modal-anim-speed ease;
		background-color: rgba(21, 25, 27, 0);
		z-index: 999;
		.modal-inner {
			transition: all $modal-anim-speed ease $modal-anim-speed*0.2;
			transform: translateY(15vh) scale(0.9);
			opacity: 0;
		}
	}
	&.modal-enter-active {
		background-color: rgba(21, 25, 27, 0.83);
		z-index: 999;
		.modal-inner {
			transform: translateY(0vh) scale(1);
			opacity: 1;
		}
	}
	&.modal-enter-done {
		background-color: rgba(21, 25, 27, 0.83);
		z-index: 999;
		.modal-inner {
			transform: translateY(0vh) scale(1);
			opacity: 1;
		}
	}

	&.modal-exit {
		transition: all $modal-anim-speed ease $modal-anim-speed*0.2;
		background-color: rgba(21, 25, 27, 0.83);
		z-index: 999;
		.modal-inner {
			transition: all $modal-anim-speed ease;
			transform: translateY(0vh) scale(1);
			opacity: 1;
		}
	}
	&.modal-exit-active {
		background-color: rgba(21, 25, 27, 0);
		z-index: 999;
		.modal-inner {
			transform: translateY(15vh) scale(0.9);
			opacity: 0;
		}
	}
	&.modal-exit-done {
		background-color: rgba(21, 25, 27, 0);
		z-index: -1;
		.modal-inner {
			transform: translateY(15vh) scale(0.9);
			opacity: 0;
		}
    }
    &.size-to-content {
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;
        .modal-inner {
            border-radius: 20px;
            width: initial;
            max-width: 100%;
            display: inline-block;
            background-color: $bg1;
            height: initial;
        }
    }
}

.logger-list {
    background-color: transparent;
    z-index: 1000;
    position: fixed;
    width: 90%;
    max-width: 600px;
    transform: translateX(-50%);
    .log {
        width: 100%;
        padding: 20px;
        border-radius: 2px;
		margin-top: 5px;
		// font-weight: bold;
		box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
		font-size: 1.1rem;
        &.error {
			color: #e25663;
			border: 1px solid;
			border-bottom: 3px solid #e25663;
			background-color: #f8d7da;
        }
        &.success {
            color: #155724;
            background-color: #d4edda;
        }
        &.warning {
            color: #856404;
            background-color: #fff3cd;
        }
        &.log-enter {
            opacity: 0;
            transform: scale(0.7) translateY(20px);
			transition: all 200ms cubic-bezier(0.68, -0.6, 0.32, 1.6);
        }
        &.log-enter-active {
            opacity: 1;
            transform: scale(1) translateY(0px);
        }
        &.log-exit {
			opacity: 1;
			height: initial !important;
			overflow-y: hidden;
            // transform: translateY(0px);
			transition: all 200ms ease;
        }
        &.log-exit-active {
            opacity: 0;
			// transform: translateY(20px);
			height: 0px !important;
			padding-top: 0px;
			padding-bottom: 0px;
			margin: 0px;
        }
    }    
}

.pagination {
	width: 100%;
    max-width: 400px;
    margin: 0 auto;
    align-items: center;
    div {
        text-align: center;
        line-height: 40px;
        height: 40px;
        color: $dark1;
        background-color: $bg2;
        font-size: 1.3rem;
        font-weight: bold;
        &.arrow {
            transform: scale(0.8);
            opacity: 0.1;
            background-color: transparent;
            display: flex;
            justify-content: center;
            &.active {
                cursor: pointer;
                opacity: 1;
                &:hover {
                    transform: scale(1);
                }
            }
        }
        &.current {
            background-color: $dark1;
			color: white;
        }
        .chevron {
            width: 25px;
            &.left {
                transform: rotate(-90deg)
            }
            &.right {
                transform: rotate(90deg);
            }
        }
    } 
}

.floating-tooltip {
	background-color: $bayColor;
	color: white;
	padding: 10px;
	font-size: 0.9rem;
	font-weight: bold;
	position: absolute;
	top: 52px;
	z-index: 1;
	width: 150px;
	right: 10px;
	box-shadow: 0px 2px 3px #00000063;
	&:after {
		content: "";
		position: absolute;
		top: -5px;
		right: 30px;
		height: 10px;
		width: 10px;
		transform: rotate(45deg);
		background-color: $bayColor;
		display: block;
	}
	&.floating-tooltip-left {
		right: initial;
		left: 10px;
		&:after {
			right: initial;
			left: 30px;
		}
	}
}

.attention {
    color: $bookedColor;
    background-color: rgba(226, 0, 70, 0.05);
    padding: 10px;
    line-height: 20px;
}